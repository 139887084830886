import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { MarketVane, MonthlyIndicatorsChart, DailyIndicatorsChart, PortfolioPerformanceChart } from "../../../components/widgets/market-vane";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/dashboard/" text="Dashboard" mdxType="Breadcrumbs" /> 
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/dashboard/indicators/"
          }}>{`Market & economic indicators`}</a></li>
      </ul>
    </Aside>
    <p>{`The core of tactical asset allocation is an understanding of the current market conditions. In bull markets, we want to maximize returns, while in bear markets, we want to manage our risk exposure. To determine the current market regime, `}<em parentName="p">{`TuringTrader`}</em>{` developed a proprietary signal, nicknamed `}<em parentName="p">{`Market Vane`}</em>{`.`}</p>
    <MarketVane mdxType="MarketVane" />
    <p>{`Our `}<em parentName="p">{`Market Vane`}</em>{` signal follows a multi-faceted approach.  First, on a monthly schedule, we evaluate factors including stock market momentum, economic growth, and the potential upside of stocks over bonds. Next, we consider implied volatility and recent drawdowns on a daily schedule. `}</p>
    <p>{`By combining these five sources, `}<em parentName="p">{`Market Vane`}</em>{` can apply sufficient filtering to avoid whipsaws and yet respond swiftly to market changes. As a result, `}<em parentName="p">{`Market Vane`}</em>{` provides high quality guidance for the fundamental risk-on versus risk-off investment decision.`}</p>
    <MonthlyIndicatorsChart mdxType="MonthlyIndicatorsChart" />
    <h2>{`Monthly Signals`}</h2>
    <p>{`At the core of `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<em parentName="p">{`Market Vane`}</em>{` is a set of three momentum signals. Because these signals change relatively slowly, they are sampled monthly for additional filtering and to avoid whipsaws. Regardless of Market Vane's sampling process, we calculate all signals daily to help investors anticipate upcoming changes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Stock Market Momentum`}</strong>{` forecasts stock market returns based on technical data such as recent historical prices.`}</li>
      <li parentName="ul"><strong parentName="li">{`Economic Momentum`}</strong>{` provides economic direction based on the recent development of weekly unemployment figures.`}</li>
      <li parentName="ul"><strong parentName="li">{`Stock vs. Bond Performance`}</strong>{` forecasts the excess gains that the stock market might offer over holding bonds, based on recent historical prices.`}</li>
    </ul>
    <DailyIndicatorsChart mdxType="DailyIndicatorsChart" />
    <h2>{`Daily Signals`}</h2>
    <p>{`While `}<em parentName="p">{`Market Vane`}</em>{`'s monthly signals provide a great foundation, they do not react swiftly enough to follow sudden market changes. To remedy this shortcoming, `}<em parentName="p">{`Market Vane`}</em>{` uses daily signals that can override the monthly data:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VIX Margin to Exit`}</strong>{` measures the current VIX relative to an exit threshold anchored on a rolling average of implied volatility.`}</li>
      <li parentName="ul"><strong parentName="li">{`Drawdowns Margin to Exit`}</strong>{` measures S&P 500 rolling drawdowns relative to an exit threshold anchored to the index's most-recent peak.`}</li>
    </ul>
    <h2>{`Interpretation`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{` See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/market-vane/"
          }}>{`Market Vane background article`}</a></li>
      </ul>
    </Aside>
    <p><em parentName="p">{`Market Vane`}</em>{` interprets its five components in a binary fashion. Values of zero or higher indicate bullish market conditions, calling for exposure to U.S. stocks. Values below zero signal bearish conditions, calling for exposure to assets with low or negative correlation to U.S. stocks, e.g., U.S. Treasuries and gold.`}</p>
    <p><em parentName="p">{`Market Vane`}</em>{` combines its five components into a single reading, which signals either bullish , or bearish conditions. To do so, it uses a majority voting mechanism for the monthly signals, with the daily signals acting as circuit breakers. For an in-depth explanation of `}<em parentName="p">{`Market Vane`}</em>{`'s operation, we recommend reading our `}<a parentName="p" {...{
        "href": "/articles/market-vane/"
      }}>{`background article on Market Vane`}</a>{`.`}</p>
    <h2>{`Learn More`}</h2>
    <p><em parentName="p">{`Market Vane`}</em>{` evaluates various market and macroeconomic signals. Curious investors should also explore our `}<a parentName="p" {...{
        "href": "/dashboard/indicators/"
      }}>{`market indicators`}</a>{` to get a broader understanding of the recent trends in stocks, bonds, commodities, forex, and the economy.`}</p>
    <ButtonPrimary text="check out market indicators" to="/dashboard/indicators/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      